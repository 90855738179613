<template>
	<v-container>
		<h1>Заняття</h1>

		<v-card
			outlined>
			<v-data-table
				:headers="headers"
				:items="lessons"
				:items-per-page="15"
				:search="search"
				:loading="loading"
				loading-text="Завантаження занять... Зачекайте, будь ласка"
				no-data-text="Немає жодного заняття"
				:item-class="() => 'cursor-pointer'"
				@click:row="routeToLesson">

				<template #top>
										
					<v-toolbar
						color="transparent"
						flat>
						<v-dialog
							v-model="createDialog"
							max-width="800px"
							persistent
							:fullscreen="$vuetify.breakpoint.xsOnly">

							<v-card
								outlined
								:loading="loading">
								<validation-observer
									ref="observer"
									v-slot="{ invalid, handleSubmit }">
									<v-form ref="editForm"
										@submit.prevent="handleSubmit(saveLesson)"
										@reset.native="cancelEditForm">

										<v-card-title>Заняття</v-card-title>
										<v-divider></v-divider>

										<v-card-text>
											<v-row>
												<v-col
													cols="12"
													md="9"
													class="py-0">
													<vv-text-field
														v-model="lesson.name"
														label="Назва заняття"
														rules="required"
														name="name"
														required
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													cols="12"
													md="3"
													class="py-0">
													<v-switch
														v-model="lesson.is_active"
														label="Активне"
														class="mt-1"
														inset>
													</v-switch>
												</v-col>
												<v-col
													cols="12"
													md="6"
													class="py-0">
													<vv-text-field
														v-model="lesson.video_id"
														label="ID відео на vimeo"
														hint="Щось типу 827235335"
														name="video_id"
														outlined
														dense>
													</vv-text-field>
												</v-col>
												<v-col
													cols="12"
													md="6"
													class="py-0">
													<vv-autocomplete
														v-model="lesson.course_id"
														:items="courses"
														item-text="name"
														item-value="id"
														label="Курс"
														name="course"
														outlined
														dense>
													</vv-autocomplete>
												</v-col>
												<v-col
													cols="12"
													class="py-0">
													<vv-textarea
														v-model="lesson.text"
														label="Текст"
														hint="Введи текст у вигляді html"
														name="description"
														outlined
														dense>
													</vv-textarea>
												</v-col>
												<v-col
													cols="12"
													class="py-0">
													<vv-autocomplete
														v-model="lesson.users"
														:items="users"
														label="Учні"
														item-text="full_name"
														item-value="id"
														rules="required"
														name="users"
														deletable-chips
														return-object
														hide-selected
														chips
														small-chips
														multiple
														required
														outlined
														dense>
													</vv-autocomplete>
												</v-col>
											</v-row>
										</v-card-text>

										<v-divider></v-divider>
										<v-card-actions>
											<v-spacer></v-spacer>
											<v-btn
												depressed
												type="reset">
												Відмінити
											</v-btn>
											<v-btn
												color="primary"
												depressed
												type="submit"
												:disabled="invalid">
												Зберегти
											</v-btn>
										</v-card-actions>
									</v-form>
								</validation-observer>
							</v-card>
						</v-dialog>

						<v-btn
							color="success"
							depressed
							small
							@click="addLesson">
							<v-icon left>mdi-plus</v-icon>
							Додати нове заняття
						</v-btn>

						<v-spacer></v-spacer>

						<v-text-field
							v-model="search"
							append-icon="mdi-magnify"
							label="Пошук"
							single-line
							hide-details
							outlined
							dense>
						</v-text-field>

					</v-toolbar>
				</template>

				<template #item.is_active="{ value }">
					<v-badge
						:color="value ? 'success' : 'error'"
						dot>
					</v-badge>
				</template>

				<template #item.files="{ value }">
					{{ value.length }}
				</template>

				<template #item.action="{ item }">
					<v-btn
						color="warning"
						icon
						@click.stop="editLesson(item)">
						<v-icon
							small>
							mdi-pencil
						</v-icon>
					</v-btn>

					<v-btn
						color="error"
						icon
						@click.stop="deleteLesson(item)">
						<v-icon
							small>
							mdi-delete
						</v-icon>
					</v-btn>
				</template>

			</v-data-table>
		</v-card>
	</v-container>
</template>

<script>
import { mapActions } from 'vuex'

import User from '../models/User'
import Course from '../models/Course'
import Lesson from '../models/Lesson'

export default {
	data: () => ({
		lesson: {},
		headers: [
			{
				text: '',
				value: 'is_active',
				align: 'center',
				width: '4em',
			},
			{
				text: 'Назва заняття',
				value: 'name',
			},
			{
				text: 'Курс',
				value: 'course.name',
			},
			{
				text: 'ID Відео',
				value: 'video_id',
			},
			{
				text: 'Файли',
				value: 'files',
			},
			{
				text: 'Учні',
				value: 'users_count',
			},
			{
				text: 'Востаннє змінено',
				value: 'updated_at',
			},
			{
				text: 'Дії',
				value: 'action',
				sortable: false,
				align: 'center',
				width: '10em',
			},
		],
		search: '',
		loading: false,
		createDialog: false,
	}),
	computed: {
		users() {
			return User.all()
		},
		courses() {
			return Course.all()
		},
		lessons() {
			return Lesson.all()
		},
	},
	methods: {
		...mapActions('notification', [
			'notify',
		]),

		async loadUsers() {
			try {
				await User.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список учнів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadCourses() {
			try {
				await Course.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список курсів',
					color: 'error',
				}, { root: true })
			}
		},

		async loadLessons() {
			try {
				this.loading = true
				await Lesson.fetchGetAll()
			} catch (error) {
				this.notify({
					text: 'Не вдалося завантажити список занять',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},
		
		async saveLesson() {
			try {
				this.loading = true
				await Lesson.fetchCreateOrUpdate(this.lesson)
				this.$refs.editForm.reset()
				this.$refs.observer.reset()
				this.createDialog = false
				this.notify({
					text: 'Заняття успішно збережено',
					color: 'success',
				}, { root: true })
			} catch (error) {
				this.notify({
					text: 'Не вдалося зберегти заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		async deleteLesson(lesson) {
			try {
				const confirm = await this.$refs.confirm.open(
					'Видалити заняття',
					'Ви дійсно хочете видалити заняття? Цю дію неможливо відмінити.',
					{ color: 'red' })
				if (confirm) {
					this.loading = true
					await Lesson.fetchDelete(lesson.districtId)
					this.notify({
						text: 'Заняття видалено',
						color: 'success',
					}, { root: true })
				}
			} catch (error) {
				this.notify({
					text: 'Не вдалося видалити заняття',
					color: 'error',
				}, { root: true })
			} finally {
				this.loading = false
			}
		},

		cancelEditForm() {
			this.lesson = new Lesson()
			this.$refs.editForm.reset()
			this.$refs.observer.reset()
			this.createDialog = false
		},
		addLesson() {
			this.lesson = new Lesson()
			this.createDialog = true
		},
		editLesson(item) {
			this.lesson = new Lesson(item)
			this.createDialog = true
		},

		/**
		 * Go to Lesson page
		 */
		routeToLesson({ id }) {
			this.$router.push({
				name: 'lesson',
				params: {
					id,
				},
			})
		},
	},
	mounted() {
		this.loadUsers()
		this.loadCourses()
		this.loadLessons()
	},
}
</script>